export const faqs = [
    {
        question: 'What is the opportunity index (OPI)?',
        answer: 'The opportunity index measures what potential trade opportunities exist between two countries, based on their social connectedness index, distance, trade flows, trade value, and language spoken.'
    },
    {
        question: 'Why the focus on Africa?',
        answer: 'The index leverages The African Continental Free Trade Area (AfCFTA), which is a free trade area, outlined in the African Continental Free Trade Agreement among 54 of the 55 African Union nations. The index aligns with, and facilitates the AfCFTA vision of creating one African market.'
    },
    {
        question: 'Who can use the opportunity index?',
        answer: 'The opportunity index can be explored by researchers, traders, governments, international organizations and other stakeholders in the trade public and private sector.'
    },
    {
        question: 'How was the index developed?',
        answer: 'The index was developed by combining several factors such Facebook’s social connectedness index, distance, trade flows, trade value etc. To get more information on the methodology, please send an email to research@cchubnigeria.com'
    },
    {
        question: 'How can I access the data?',
        answer: 'On this platform, you can explore the data by selecting the country you want to export from, quickly & easily glance through a large network of trade opportunities. Researchers and universities can further explore the data by sending an email to research@cchubnigeria.com with a detailed description of the project.'
    },
    {
        question: 'How do I interpret the index?',
        answer: 'The index represents the % chance of potential trade between the exporting country and other countries. It ranges from 0 (lowest) to 100 (highest). \n\nThe following assumptions are made by the model used to estimate the index:Assumptions of the index are: 1) AfCTFA will remove trade restrictions between countries in Africa 2) A country’s fixed effects will take care of any unobserved heterogeneity, 3) SCI is endogeneous'
    }
]