export const countries = [
  {
    country: "Algeria",
    country_code: "DZ",
    country_index: 0.3991,
    region: "Northern Africa"
  },
  {
    country: "Angola",
    country_code: "AO",
    country_index: 0.4908,
    region: "Southern Africa"
  },
  {
    country: "Benin",
    country_code: "BJ",
    country_index: 0.3489,
    region: "Western Africa"
  },
  {
    country: "Botswana",
    country_code: "BW",
    country_index: 0.1074,
    region: "Southern Africa"
  },
  {
    country: "Burkina Faso",
    country_code: "BF",
    country_index: 0.3926,
    region: "Western Africa"
  },
  {
    country: "Burundi",
    country_code: "BI",
    country_index: 0.209,
    region: "Central Africa"
  },
  {
    country: "Cameroon",
    country_code: "CM",
    country_index: 0.4047,
    region: "Central Africa"
  },
  {
    country: "Cape Verde",
    country_code: "CV",
    country_index: 0.1794,
    region: "Western Africa"
  },
  {
    country: "Central African Republic",
    country_code: "CF",
    country_index: 0.144,
    region: "Central Africa"
  },
  {
    country: "Chad",
    country_code: "TD",
    country_index: 0.1229,
    region: "Central Africa"
  },
  {
    country: "Comoros",
    country_code: "KM",
    country_index: 0.0705,
    region: "Eastern Africa"
  },
  {
    country: "Congo - Brazzaville",
    country_code: "CG",
    country_index: 0.2643,
    region: "Central Africa"
  },
  {
    country: "Congo - Kinshasa",
    country_code: "CD",
    country_index: 0.2847,
    region: "Central Africa"
  },
  {
    country: "Cote d'Ivoire",
    country_code: "CI",
    country_index: 0.5932,
    region: "Western Africa"
  },
  {
    country: "Djibouti",
    country_code: "DJ",
    country_index: 0.1789,
    region: "Eastern Africa"
  },
  {
    country: "Egypt",
    country_code: "EG",
    country_index: 0.7296,
    region: "Northern Africa"
  },
  {
    country: "Equatorial Guinea",
    country_code: "GQ",
    country_index: 0.1578,
    region: "Central Africa"
  },
  {
    country: "Eritrea",
    country_code: "ER",
    country_index: 0.0814,
    region: "Eastern Africa"
  },
  {
    country: "Eswatini",
    country_code: "SZ",
    country_index: 0.0242,
    region: "Southern Africa"
  },
  {
    country: "Ethiopia",
    country_code: "ET",
    country_index: 0.3528,
    region: "Eastern Africa"
  },
  {
    country: "Gabon",
    country_code: "GA",
    country_index: 0.3302,
    region: "Central Africa"
  },
  {
    country: "Gambia",
    country_code: "GM",
    country_index: 0.178,
    region: "Western Africa"
  },
  {
    country: "Ghana",
    country_code: "GH",
    country_index: 0.5909,
    region: "Western Africa"
  },
  {
    country: "Guinea",
    country_code: "GN",
    country_index: 0.2708,
    region: "Western Africa"
  },
  {
    country: "Guinea-Bissau",
    country_code: "GW",
    country_index: 0.0506,
    region: "Western Africa"
  },
  {
    country: "Kenya",
    country_code: "KE",
    country_index: 0.7266,
    region: "Eastern Africa"
  },
  {
    country: "Lesotho",
    country_code: "LS",
    country_index: 0.0289,
    region: "Southern Africa"
  },
  {
    country: "Liberia",
    country_code: "LR",
    country_index: 0.2276,
    region: "Western Africa"
  },
  {
    country: "Libya",
    country_code: "LY",
    country_index: 0.1705,
    region: "Northern Africa"
  },
  {
    country: "Madagascar",
    country_code: "MG",
    country_index: 0.4342,
    region: "Eastern Africa"
  },
  {
    country: "Malawi",
    country_code: "MW",
    country_index: 0.264,
    region: "Southern Africa"
  },
  {
    country: "Mali",
    country_code: "ML",
    country_index: 0.342,
    region: "Western Africa"
  },
  {
    country: "Mauritania",
    country_code: "MR",
    country_index: 0.2489,
    region: "Northern Africa"
  },
  {
    country: "Mauritius",
    country_code: "MU",
    country_index: 0.2548,
    region: "Eastern Africa"
  },
  {
    country: "Morocco",
    country_code: "MA",
    country_index: 0.7192,
    region: "Northern Africa"
  },
  {
    country: "Mozambique",
    country_code: "MZ",
    country_index: 0.4727,
    region: "Southern Africa"
  },
  {
    country: "Namibia",
    country_code: "NA",
    country_index: 0.0473,
    region: "Southern Africa"
  },
  {
    country: "Niger",
    country_code: "NE",
    country_index: 0.3174,
    region: "Western Africa"
  },
  {
    country: "Nigeria",
    country_code: "NG",
    country_index: 0.6329,
    region: "Western Africa"
  },
  {
    country: "Rwanda",
    country_code: "RW",
    country_index: 0.2365,
    region: "Eastern Africa"
  },
  {
    country: "Sao Tome and Principe",
    country_code: "ST",
    country_index: 0.1347,
    region: "Central Africa"
  },
  {
    country: "Senegal",
    country_code: "SN",
    country_index: 0.5875,
    region: "Western Africa"
  },
  {
    country: "Seychelles",
    country_code: "SC",
    country_index: 0.2552,
    region: "Eastern Africa"
  },
  {
    country: "Sierra Leone",
    country_code: "SL",
    country_index: 0.3365,
    region: "Western Africa"
  },
  {
    country: "Somalia",
    country_code: "SO",
    country_index: 0.1239,
    region: "Eastern Africa"
  },
  {
    country: "South Africa",
    country_code: "ZA",
    country_index: 0.7464,
    region: "Southern Africa"
  },
  {
    country: "South Sudan",
    country_code: "SS",
    country_index: 0.0784,
    region: "Eastern Africa"
  },
  {
    country: "Sudan",
    country_code: "SD",
    country_index: 0.2378,
    region: "Eastern Africa"
  },
  {
    country: "Tanzania",
    country_code: "TZ",
    country_index: 0.6194,
    region: "Eastern Africa"
  },
  {
    country: "Togo",
    country_code: "TG",
    country_index: 0.4128,
    region: "Western Africa"
  },
  {
    country: "Tunisia",
    country_code: "TN",
    country_index: 0.6569,
    region: "Northern Africa"
  },
  {
    country: "Uganda",
    country_code: "UG",
    country_index: 0.5737,
    region: "Eastern Africa"
  },
  {
    country: "Zambia",
    country_code: "ZM",
    country_index: 0.5451,
    region: "Southern Africa"
  },
  {
    country: "Zimbabwe",
    country_code: "ZW",
    country_index: 0.456,
    region: "Southern Africa"
  }
]
